.corner_border {
    position: relative;
    width: 80vw;           /* Width of the box */
    height: 80vh;          /* Height of the box */
}

/* Top-left corner */
.corner_border::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border-top: 5px solid #76b947;  /* Green top border */
    border-left: 5px solid #76b947; /* Green left border */
    border-top-left-radius: 20px;   /* Rounded corner */
}

/* Bottom-right corner */
.corner_border::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    border-bottom: 5px solid #76b947;  /* Green bottom border */
    border-right: 5px solid #76b947;   /* Green right border */
    border-bottom-right-radius: 20px;  /* Rounded corner */
}

.corner_border .top_right {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    border-top: 5px solid #76b947;    /* Green top border */
    border-right: 5px solid #76b947;  /* Green right border */
    border-top-right-radius: 20px;    /* Rounded corner */
}

/* Bottom-left corner */
.corner_border .bottom_left {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border-bottom: 5px solid #76b947; /* Green bottom border */
    border-left: 5px solid #76b947;   /* Green left border */
    border-bottom-left-radius: 20px;  /* Rounded corner */
}
.webcam{
    height: 95%;
    width: 98.5%;
    padding-top: 10px  !important;
    padding-left: 10px !important;
    position: relative !important;
    // z-index: -1 !important;
}
@media (max-width: 600px) {
    .webcam{
        width: 95% !important;
    }
  }