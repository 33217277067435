.detail_textArea {
    border: 1px solid rgb(138, 189, 90) !important;
    padding: 5px !important;
    outline: none;
}
.select{
    width: 30%;
    height: 40px !important;
    // border: 2px solid red;
    padding: 7px;
    border-radius: 6px;
    margin-top:10px;
}
.input{
    height: 40px !important;
    margin-top: 10px !important;
    font-weight: bolder;
}
.dropdown{
    font-weight: bold !important;
    height: 40px !important;
    background-color: #FFFFFF;
    margin-top: 10px;
}