.drawing_button {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid gainsboro;
    // padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.5s;
    margin-bottom: 7px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.drawing_button:hover {
    background-color: #8ABD5A;
}

.drawing_button:hover > svg {
    color: white !important;
}

.drawing_button > svg {
    font-size: 1.3rem;
}
.uploaderContainer> div {
    width: 100% !important;
}