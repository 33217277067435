.bg_image_container {
  background-image: url("../../../assets/images/login-bg-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.login_card {
  border-radius: 40px;
}
.login_card_main_container {
  width: 500px;
}
.k_input {
  height: 50px !important;
  border-radius: 10px;
  padding: 23px !important;
}
.heading{
  font-size: 36px;
}
@media (max-width: 600px) {
  .login_card_main_container,
  .login_card{
    width: 100% !important;
  }
}
