.input{
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    height: 16px;
    font-size: 16px;
    font-weight: bold;
        -webkit-appearance: none; 
    -webkit-border-radius: 0;
    -webkit-box-sizing: border-box; /* iOS compatibility */
}
.input:focus {
    outline: none; /* to remove the default outline */
    border: none;
    border-bottom: 2px solid black;
}
.input:disabled{
    background-color: transparent !important;
}

// .input {
//     width: 100%;
//     border: none;
//     border-bottom: 2px solid black;
//     height: 15px;
//     font-size: 16px;
//     font-weight: bold;
//     line-height: 1.5; 
//     -webkit-appearance: none; 
//     -webkit-border-radius: 0;
// }

// .input:focus {
//     outline: none; 
//     border-bottom: 2px solid black; 
// }

// .input:disabled {
//     background-color: transparent; 
//     cursor: not-allowed; 
// }
