
  #dup-scanning-files {
    $color: #76b947;
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .scanning-wrapper {
        height: -webkit-fill-available;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
  
      $corner-border-width: 5px;
  
      .top-corners,
      .bottom-corners {
        position: absolute;
        left: 0;
        top: 0;
        width: 3em;
        height: 100%;
  
        &::before,
        &::after {
          height: 3em;
          width: 3em;
          border: 0 solid $color;
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
        }
  
        &::before {
          top: 0;
        }
      }
  
      .bottom-corners {
        left: unset;
        right: 0;
  
        &::before {
          border-right-width: $corner-border-width;
          border-top-width: $corner-border-width;
        }
  
        &::after {
          border-right-width: $corner-border-width;
          border-bottom-width: $corner-border-width;
        }
      }
  
      .top-corners {
        &::before {
          border-left-width: $corner-border-width;
          border-top-width: $corner-border-width;
        }
  
        &::after {
          border-left-width: $corner-border-width;
          border-bottom-width: $corner-border-width;
        }
      }
    }
  
    .scanner {
      width: 80%;
      height: 10px;
      background-color: $color;
      position: absolute;
      top: 10%;
      animation: scan-vertical 2s cubic-bezier(0.46, 0.55, 0.59, 0.95) alternate infinite;
  
      &::before,
      &::after {
        content: '';
        height: 0px;
        width: 100%;
        left: 0;
        position: absolute;
      }
  
      &::after {
        top: 100%;
        background: linear-gradient(180deg, transparent, rgba($color, 0.5));
        animation: scan-tail-vertical 4s cubic-bezier(0.46, 0.55, 0.59, 0.95) infinite 2s;
      }
  
      &::before {
        bottom: 100%;
        background: linear-gradient(0deg, transparent, rgba($color, 0.5));
        animation: scan-tail-vertical 4s cubic-bezier(0.46, 0.55, 0.59, 0.95) infinite;
      }
    }
  
    .file-types {
        display: flex;
        justify-content: center;
      width: 100%;
    //   height: 200px;
  
      position: relative;
  
      .file-types-carousel {
        font-size: 10em;
        width: 1em;
        overflow: hidden;
        position: relative;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: scan-file-scale 2s infinite;
  
        .file-types-track {
          animation: scan-file-translate-vertical 12s steps(6) infinite 0s;
        }
      }
  
      i {
        display: block;
  
        &.fa-file-pdf {
          color: #c21219;
        }
  
        &.fa-file-word {
          color: #295394;
        }
  
        &.fa-file-image {
          color: #057948;
        }
  
        &.fa-file-audio {
          color: #0f5268;
        }
  
        &.fa-file-video {
          color: #ce1312;
        }
  
        &.fa-file-alt {
          color: #3a3a3a;
        }
      }
    }
  }
  
  @keyframes scan-file-translate-vertical {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(-6em);
    }
  }
  
  @keyframes scan-vertical {
    0%,
    40% {
      top: 5%;
    }
  
    70%,
    100% {
      top: 95%;
    }
  }
  
  @keyframes scan-tail-vertical {
    0%,
    20% {
      height: 0%;
    }
  
    25% {
      height: 800%;
    }
  
    40%,
    100% {
      height: 0%;
    }
  }
  @media (max-width: 600px) {
    .file-types>img {
        width: 100% !important;
        height: 300px !important;
    }
    #dup-scanning-files{
        height: 350px;
    }
  }