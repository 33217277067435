.k_action_button_container {
}
.k_action_button_container > button > span > svg {
  font-size: 1.5rem;
  color: black;
}
.k_action_button_container > button {
  height: 40px;
  width: 50px;
}
.k_action_button_container>:nth-child(1){
    background-color: #D43F3A;
}
.k_action_button_container>:nth-child(2){
background-color: #2E6DA4;
}
.k_action_button_container>:nth-child(3){
background-color: #4CAE4C;
}
