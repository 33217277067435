.container-dailog {
    display: flex;
  }
  #modal-comp {
    z-index: 3;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .dialog {
      position: fixed;
    left: 50%;
    top: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    width: 55rem;
    border-radius: 1rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 1rem rgba(37, 38, 41, 0.12);
  }
  .dialog:focus {
      outline: none;
  }
  
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .modal-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;
  }
  .modal-title {
    font-weight: 600;
  }
  .close {
    width: 1.5rem;
    height: 1.5rem;
  }
  .modal-btn {
    background: #DCFAFB;
    color: #8f8f8f;
    font-size: 20px;
    padding: 16px;
    border-radius: 1rem;
    box-shadow: 0rem 0rem 1rem rgba(37, 38, 41, 0.12);
    display: flex;
    justify-content: center;
  }
  .icon {
    width: 20px;
  }
  .clickable:hover {
    cursor: pointer;
  }
.close-svg{
  font-weight: bolder;
  font-size: 1.8rem;
  position: relative;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media (max-width: 968px) {
  .dialog {
    width: 80%; /* Sets width to 80% of viewport on smaller screens */
  }
}

// .myCanvusandImage{
//   height: 500px;
// }

.k-scrollable{
  max-height: 500px;
  overflow-y: auto;
}


@media (max-width: 600px) {
  .myCanvusandImage {
    width: 100% !important; 
  }
  .myCanvusandImage2{
    height: 300px !important;
  }
  .k-scrollable{
    max-height: 500px;
    overflow-y: scroll;
  }

  .ReactCrop__image{
    height: 300px !important;
  }
  
}