// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// .App-header {
//   background-color: #151950;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }
.k-button-green{
    background-color: #8ABD5A !important;
    color: white !important;
    border: none !important;
}
.k-button-blue{
    background-color: #46B8DA !important;
    color: white !important;
    border: none !important;
}
.k-button-darkBlue{
    background-color: #03A9F4 !important;
    color: white !important;
    border: none !important;
    font-weight: bold;
}
.k-button-red{
    background-color: #FC5658 !important;
    color: #777879 !important;
    border: none !important;
}
.k-button-red2{
    background-color: #FC5658 !important;
    color: white !important;
    border: none !important;
}
body{
    background-color: #eee;
    font-family: "ABeeZee" !important;
}


.container {
    max-width: 1536px;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .k-sm-w-res{
    width: 58% !important;
  }
  .k-sm-w-40{
    width: 40% !important;
  }
  
  @media (max-width: 1536px) {
    .container {
      max-width: 1366px;
    }
  }
  
  @media (max-width: 1366px) {
    .container {
      max-width: 1280px;
    }
  }
  
  @media (max-width: 1280px) {
    .container {
      max-width: 1024px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  @media (max-width: 1024px) {
    .container {
      max-width: 768px;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      max-width: 640px;
    }
  }
  
  @media (max-width: 640px) {
    .container {
      max-width: 475px;
    }
  }
  
  @media (max-width: 475px) {
    .container {
      max-width: 380px;
    }
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
  -moz-appearance: textfield;
}


input[type="radio"]:checked:disabled::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: #8ABD5A; /* Custom green color */
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}
input[type="radio"]:checked:disabled {
  position: relative; /* Ensures ::before is properly placed */
}

input[type="radio"]:disabled {
  opacity: 0.6; /* Makes disabled radios appear faded */
  cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
  input {
    font-size: 16px !important;
  }
  .k-md-flex-column {
   flex-direction: column !important;
  }
  .k-md-gap-less{
    gap: 20px !important;
  }
  .k-md-w-auto{
    width: 100% !important;
  }
  .k-less-padding{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

@media only screen and (max-width: 600px) {
.k-responsive-text{
  text-align: left !important;
  font-size: 15px !important;
}
.k-button-sm-responsive{
  width: 100% !important;
}
.k-sm-px-2{
  padding: 10px !important;
}
.k-w-sm--100{
  width: 92% !important;
}
.k-grid-sm-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
.k-grid-sm-cols-1 > h4 {
  margin: 5px !important;
}
.k-border-r-sm{
  border: none !important;
}
.k-sm-sm-0{
  margin-left:0px !important;
}
.k-mt-sm-2{
  margin: 0px !important;
}
.k-sm-gap-2{
    gap: 4px !important;
}
.k-sm-gap-2{
   gap: 1px !important;
}
.k-sm-flex-0{
  flex: 0 0 100% !important;
}
.k-sm-right-0{
  right: 0px !important;
}
.k-sm-gap-0{
  gap: 1px !important;
}
.k-sm-top-0{
  top: 0px !important;
  bottom: 3px !important;
}
.k-pt-sm-4{
  padding-top: 20px !important;
}
.k-sm-top-2{
  margin-top: 10px;
}
.k-w-sm-100{
  width: 100% !important;
}
.k-sm-pl-0{
  padding-left: 0px !important;
}
.k-sm-w-res{
  width: 100% !important;
}
.k-sm-w-40{
  width: 100% !important;
}
.k-sm-w-100 {
  width: 100% !important;
}
.k-sm-m-0{
  margin:10px 0px !important;
}
.k-sm-w-160{
  width: 160px !important;
}
.k-sm-fs{
  font-size: 14px !important;
}
.k-sm-w-logo{
  max-width: 90px !important;
}
.k-sm-fs-15{
  font-size: 18px !important;
}
.k-sm-text-left{
  text-align: left !important;
}
.k-sm-justify-content-start{
  justify-content: flex-start !important;
}
.k-sm-w-img{
  width: 50px !important;
}
.k-left-sm-5{
  left: 5px !important;
}
.k-flex-direction-sm{
  flex-direction: column !important;
  // justify-content: flex-start !important;
  align-items: flex-start !important;
}
.k-w-sm-90{
  width: 95% !important;
}
.k-sm-justify-content-between{
  justify-content: space-between !important;
}
.k-sm-display-none{
  display: none !important;
}
.grid-tem-column-sm{
  grid-template-columns: repeat(1, 1fr) !important;
  text-align: left !important;
}
.k-sm-m-10{
  margin: 10px 0px;
}
.k-ml-sm-0{
  margin-left: 3px !important;
}
.k-d-flex-sm-cloumn{
  display: flex !important;
  flex-direction: column !important;
  gap: 5px;
}
.k-sm-w-90{
  width: 70% !important;
}
.k-sm-m-0{
  margin-bottom: 0px !important;
}
.k-sm-mt-4{
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}
.k-r-sm{
  right: 0px !important;
  justify-content: flex-start !important;
}
.k-sm-pl-0{
  padding-left: 0px !important;
}
}
.stories-input>input{
  width: 109% !important;
}
.k-manage-width>div>input{
width: 100px;
}